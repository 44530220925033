<script>
export default {
  name: 'PageAlert',
  props: [
    'light'
  ],  
  data() {
      return {
          elementVisible: true
      }
  },

  created() {
      //setTimeout(() => this.elementVisible = false, 5000)
  }
}
</script>
<template>
  <div v-show="elementVisible">
    <div v-if="this.$parent.MyPageAlert?.status==='success'" :class="light?'alert c_green mt-3':'alert alert-success'">							
      <i class="fa fa-check mr-2"></i> {{ this.$parent.MyPageAlert.message}}		
    </div>
    <div v-if="this.$parent.MyPageAlert?.status==='error'" class="alert alert-danger">							
      <i class="fa fa-exclamation mr-2"></i> {{ this.$parent.MyPageAlert.message}}				
    </div>
  </div>
</template>